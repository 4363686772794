import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Layout from "../components/layout"
import SEO from "../components/seo"
import InstallationServices from "../components/installationServices"

const BeamsIJoists = () => {
  const data = useStaticQuery(graphql`
    query {
      photo1: file(relativePath: { eq: "gallery/Interior_construction.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            src
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Beams &amp; I-Joists" />
      <Helmet
        bodyAttributes={{
          class: "page-beams-i-joists",
        }}
      />
      <Row>
        <Col sm={12}>
          <h1>Beam &amp; I-Joists</h1>
        </Col>
        <Col md={9}>
          <h3>Engineered Beams</h3>
          <img
            className="img-fluid float-right ml-3 mb-3"
            src={data.photo1.childImageSharp.fluid.src}
            alt="Interior Construction"
          />
          <p>
            Capital Structures' beams are specifically designed to outperform
            traditional solid wood beams. The products are made from
            ultrasonically and visually graded veneers, and patterned to prevent
            naturally occurring defects from affecting the beams' performance.
            They are then bonded together, under pressure and heat, with
            waterproof adhesives. The process ensures that our products are
            exceptionally strong, solid, and straight, making them excellent for
            most primary load-carrying beam applications.
          </p>
          <p>
            With Capital Structures beams, problems that naturally occur as
            solid sawn lumber dries (twisting, splitting, checking, crowning,
            and warping) are greatly reduced. Compared to wood milled directly
            from trees, Capital Structures’ beams are available in longer and
            thicker dimensions, yet they aren't made from old-growth trees. Our
            beams have more load-carrying capacity than traditional lumber. The
            end result is a more reliable, better-performing, and more usable
            product.
          </p>
          <h3>Engineered I-joists</h3>
          <p>
            Prefabricated wood I-joists are made by gluing solid sawn lumber
            flanges to a plywood or strandboard (OSB) panel web to produce a
            dimensionally stable, light-weight member with known engineering
            properties.
          </p>
          <p>
            The uniform stiffness, strength, and light weight of these
            prefabricated structural products makes them well suited for longer
            span joist and rafter applications for both residential and
            commercial construction.
          </p>
          <Button href="/contact" variant="primary">
            Get Started Today
          </Button>
        </Col>
        <Col md={3}>
          <InstallationServices />
        </Col>
      </Row>
    </Layout>
  )
}

export default BeamsIJoists
